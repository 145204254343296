<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="flex flex-wrap items-center">
                <div class="flex-grow">
                </div>
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="workShiftList"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="20"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import CellRendererActions from "./cell-renderer/OrganizationCellRendererActions.vue"
import utils from "../../../components/utils";

export default {
    components: {
        AgGridVue,

        // Cell Renderer
        CellRendererActions,
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'organizationBranchId',
                    width: 100
                },
                {
                    headerName: 'Tên chi nhánh',
                    field: 'organizationBranchName',
                    width: 200
                },
                {
                    headerName: 'Cài đặt',
                    field: 'workShiftSetting',
                    width: 550,
                },
                {
                    headerName: 'Actions',
                    field: 'actions',
                    width: 100,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
            },
            workShiftList: []
        }
    },
    created() {
        this.initScreen()
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        }
    },
    computed: {
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        addWorkShift() {
            this.$router.push('/user/add-work-shift').catch(() => {
            });
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.$vs.loading();
            this.$crm.post('/work-shift/organization/all').then((response) => {
                this.workShiftList = response.data.map(item => {
                    item.workShiftSetting = item.workShiftSetting.map(workShiftSetting => workShiftSetting.name + ": " + utils.minuteToHour(workShiftSetting.startHour) + " - " + utils.minuteToHour(workShiftSetting.endHour)).join(", ");
                    return item;
                });
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    }
}
</script>

<style scoped>

</style>
