<template>
    <div class="vx-col md:w-full sm:w-auto">
        <div class="vx-col md:w-full">
            <vx-tooltip class="position-icon" text="Chỉnh sửa" position="top">
                <feather-icon icon="EditIcon" class="action-success icon-btn"
                              svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                              @click="editRecord"/>
            </vx-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OrganizationCellRendererActions',
    methods: {
        editRecord() {
            this.$router.push(`/user/manage-branch-work-shift?branch_id=${this.params.data.organizationBranchId}`).catch(() => {
            })
        },
        confirmDeleteRecord() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận xoá`,
                text: `Bạn có chắc là muốn xoá "${this.params.data.branch_name}" không ?`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },
        deleteRecord() {
            this.showDeleteSuccess()
        },
        showDeleteSuccess() {
            this.$vs.notify({
                color: 'success',
                text: 'Xoá chi nhánh thành công',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        }
    }
}
</script>
<style scoped>
.f-r {
    float: right;
}

.icon-action {
    color: rgba(var(--vs-primary),1);
}

.icon-action:hover {
    color: rgba(var(--vs-primary),1);
    opacity: 0.5;
}
</style>
